var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [!_vm.section ? _c('span', [_vm._v("Please create a section")]) : _c('span', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.section.key))]), _c('a', {
    staticClass: "ml-2",
    on: {
      "click": function click($event) {
        _vm.aside = true;
        _vm.resource = _vm.section;
      }
    }
  }, [_vm._v("Edit")])])]), _c('v-list', {
    staticClass: "resource-list",
    nativeOn: {
      "mouseover": function mouseover($event) {
        return _vm.$emit('focus', 'section');
      },
      "click": function click($event) {
        return _vm.$emit('focus', 'section');
      }
    }
  }, [_vm._l(_vm.tableItems, function (item) {
    return _c('v-list-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.shouldShow(item),
        expression: "shouldShow(item)"
      }],
      key: "".concat(item.id).concat(item.updated_at),
      staticClass: "resource",
      class: {
        'v-list-item--active': _vm.section && _vm.section.id === item.id
      },
      on: {
        "click": function click($event) {
          return _vm.toSection(item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "d-flex align-center"
    }, [_c('v-badge', {
      attrs: {
        "color": "secondary",
        "content": item.sort || '0',
        "inline": ""
      }
    }), item.type === 'plain' ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "size": "30"
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiText) + " ")]) : _vm._e(), item.type === 'carousel' ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "size": "30"
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiImageMultiple) + " ")]) : _vm._e(), item.type === 'component' ? _c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "size": "30"
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiPuzzle) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(item.key) + " "), item.element.class && item.element.class.length ? _c('div', {
      staticClass: "text--secondary text-xs"
    }, _vm._l(item.element.class, function (className) {
      return _c('span', {
        key: className
      }, [_vm._v(" " + _vm._s(className) + " ")]);
    }), 0) : _vm._e()]), _c('device-icon', {
      attrs: {
        "item": item
      }
    }), item.guest && (!item.auth || item.auth_state) ? _c('v-chip', [_vm._v(" GUEST ")]) : _vm._e(), item.auth && (!item.guest || item.auth_state) ? _c('v-chip', {
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" AUTH "), item.auth_state ? _c('span', [_vm._v(":" + _vm._s(item.auth_state))]) : _vm._e()]) : _vm._e(), !item.active ? _c('v-chip', {
      attrs: {
        "color": "error"
      }
    }, [_vm._v(" Disabled ")]) : _vm._e()], 1), _c('div', {
      staticClass: "text--secondary mt-1"
    }, [item.selection ? _c('v-chip', {
      attrs: {
        "color": "info"
      },
      on: {
        "click": function click($event) {
          return _vm.goToSelection(item.selection);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiGestureTap) + " ")]), _vm._v(" " + _vm._s(item.selection.key) + " ")], 1) : _vm._e(), item.feature ? _c('v-chip', {
      attrs: {
        "color": "info"
      }
    }, [_vm._v(" " + _vm._s(item.feature.key) + " ")]) : _vm._e(), item.notes ? _c('p', [_vm._v(" " + _vm._s(item.notes) + " ")]) : _vm._e()], 1)], 1)], 1);
  }), _vm.page.custom_sections ? _c('v-list-item', {
    staticClass: "resource"
  }, [_c('a', {
    on: {
      "click": function click($event) {
        _vm.aside = true;
        _vm.resource = null;
      }
    }
  }, [_vm._v(" Add new customizable sections ")])]) : _vm._e(), _vm.section ? _c('v-list-item', {
    staticClass: "resource"
  }, [_vm.section.key.includes('faq_') ? _c('ReplicateSection', {
    attrs: {
      "items": _vm.tableItems
    },
    on: {
      "created": _vm.loadData
    }
  }) : _vm._e()], 1) : _vm._e()], 2), _vm.aside ? _c('site-sections-aside', {
    attrs: {
      "resource": _vm.resource,
      "page-id": _vm.page.id
    },
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }