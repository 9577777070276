var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h2', [!_vm.page ? _c('span', [_vm._v("Pages")]) : _c('span', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.page.href))]), _c('a', {
    on: {
      "click": function click($event) {
        _vm.aside = true;
        _vm.resource = _vm.page;
      }
    }
  }, [_vm._v("Edit")])])]), _c('v-list', {
    staticClass: "resource-list",
    nativeOn: {
      "mouseover": function mouseover($event) {
        return _vm.$emit('focus', '');
      },
      "click": function click($event) {
        return _vm.$emit('focus', '');
      }
    }
  }, [_vm._l(_vm.tableItems, function (item) {
    return _c('v-list-item', {
      key: "".concat(item.id).concat(item.updated_at),
      staticClass: "resource",
      class: {
        'v-list-item--active': _vm.page && _vm.page.id === item.id
      },
      on: {
        "click": function click($event) {
          return _vm.toPage(item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [item.href.includes('/') ? _c('v-icon', {
      attrs: {
        "size": "30"
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiWeb) + " ")]) : _vm._e(), item.custom_sections ? _c('v-icon', {
      attrs: {
        "size": "30"
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiPuzzle) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(item.href) + " ")], 1), _c('p', {
      staticClass: "text--secondary mt-1"
    }, [_vm._v(" " + _vm._s(item.notes) + " ")])], 1)], 1);
  }), _c('v-list-item', {
    staticClass: "resource"
  }, [_c('a', {
    on: {
      "click": function click($event) {
        _vm.aside = true;
        _vm.resource = null;
      }
    }
  }, [_vm._v(" Create Customizable Page ")])])], 2), _vm.aside ? _c('site-pages-aside', {
    attrs: {
      "site-id": _vm.site.id,
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
        _vm.resource = _vm.page;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }