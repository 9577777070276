var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [!_vm.item ? _c('span', [_vm._v("Items")]) : _c('span', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.item.key))]), _c('a', {
    on: {
      "click": function click($event) {
        _vm.aside = true;
        _vm.resource = _vm.item;
      }
    }
  }, [_vm._v("Edit")])])]), _c('v-list', {
    staticClass: "resource-list",
    nativeOn: {
      "mouseover": function mouseover($event) {
        return _vm.$emit('focus', 'item');
      },
      "click": function click($event) {
        return _vm.$emit('focus', 'item');
      }
    }
  }, [_vm._l(_vm.tableItems, function (record) {
    return [_vm.shouldShow(_vm.item) ? [_c('SiteViewItem', {
      key: "".concat(record.id, "-").concat(record.updated_at),
      staticClass: "resource",
      class: {
        'v-list-item--active': _vm.item && _vm.item.id === record.id
      },
      attrs: {
        "item": record
      },
      nativeOn: {
        "click": function click($event) {
          _vm.$emit('click-item', record);
          _vm.resource = record;
          _vm.aside = true;
        }
      }
    }), _vm._l(record.children, function (child) {
      return _c('SiteViewItem', {
        key: "".concat(child.id, "-").concat(child.updated_at),
        staticClass: "resource pl-8",
        class: {
          'v-list-item--active': _vm.item && _vm.item.id === child.id
        },
        attrs: {
          "item": child
        },
        nativeOn: {
          "click": function click($event) {
            _vm.$emit('click-item', child);
            _vm.resource = record;
            _vm.aside = true;
          }
        }
      });
    })] : _vm._e()];
  }), _vm.page.custom_sections ? _c('v-list-item', {
    staticClass: "resource"
  }, [_c('a', {
    on: {
      "click": function click($event) {
        _vm.aside = true;
        _vm.resource = null;
      }
    }
  }, [_vm._v(" Add new customizable items ")])]) : _vm._e(), _c('v-list-item', {
    staticClass: "resource"
  }, [_vm.canReplicateItem(_vm.section.key) ? _c('ReplicateSiteItem', {
    attrs: {
      "items": _vm.tableItems
    },
    on: {
      "created": _vm.reload
    }
  }) : _vm._e()], 1)], 2), _vm.aside ? _c('site-items-aside', {
    attrs: {
      "section-id": _vm.section.id,
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.reload();
        _vm.resource = null;
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }