var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    staticClass: "cursor-pointer"
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "d-flex align-center"
  }, [_c('v-badge', {
    attrs: {
      "color": "secondary",
      "content": _vm.item.sort || '0',
      "inline": ""
    }
  }), _vm.item.type === 'css' ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "30"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiCodeBraces) + " ")]) : _vm._e(), _vm.item.type === 'image' ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "30"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiImage) + " ")]) : _vm._e(), _vm.item.type === 'link' ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "30"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiLink) + " ")]) : _vm._e(), ['title', 'content'].includes(_vm.item.type) ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "30"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiText) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.item.key) + " "), _vm.item.element.class && _vm.item.element.class.length ? _c('div', {
    staticClass: "text--secondary text-xs"
  }, _vm._l(_vm.item.element.class, function (className) {
    return _c('span', {
      key: className
    }, [_vm._v(" " + _vm._s(className) + " ")]);
  }), 0) : _vm._e()]), _c('device-icon', {
    attrs: {
      "item": _vm.item
    }
  }), !_vm.item.active ? _c('v-chip', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" Disabled ")]) : _vm._e(), _vm.item.feature ? _c('v-chip', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.feature.key) + " ")]) : _vm._e(), _vm.item.guest && (!_vm.item.auth || _vm.item.auth_state) ? _c('v-chip', [_vm._v(" GUEST ")]) : _vm._e(), _vm.item.auth && (!_vm.item.guest || _vm.item.auth_state) ? _c('v-chip', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v(" AUTH "), _vm.item.auth_state ? _c('span', [_vm._v(":" + _vm._s(_vm.item.auth_state))]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "text--secondary mt-1"
  }, [_vm.item.notes ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.item.notes))]) : _vm._e(), _vm.item.element.text ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.t(_vm.item.element.text)))]) : _vm._e(), _vm.item.element.href ? _c('b', {
    staticClass: "ml-2"
  }, [_vm._v("(" + _vm._s(_vm.item.element.href) + ")")]) : _vm._e(), _vm.item.element.content ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("(" + _vm._s(_vm.item.element.content) + ")")]) : _vm._e()])], 1), _vm.getImageSrc(_vm.item) ? _c('v-list-item-avatar', {
    attrs: {
      "size": "38"
    }
  }, [_c('v-img', {
    style: {
      maxWidth: '100%',
      maxHeight: '100px'
    },
    attrs: {
      "src": _vm.getImageSrc(_vm.item)
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }