<template>
  <div>
    <template v-if="site">
      <h2>
        <b>{{ site.nicename }}</b>

        <v-chip
          v-if="site.main"
          class="ml-2"
        >
          MAIN
        </v-chip>
      </h2>

      <v-row>
        <v-col cols="9">
          {{ urlSetting.value }}

          <router-link :to="{ name: 'site-setting-list', params: { id: site.id }}">
            Manage Settings
          </router-link>
        </v-col>
        <v-col cols="3">
          <device-filter />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="flex-col"
          cols="12"
          :md="colFocused('page')"
        >
          <SiteViewPageList
            :site="site"
            :page="page"
            @focus="part => focusing = part"
            @click-page="p => { page = p}"
          />
        </v-col>
        <v-col
          class="flex-col"
          cols="12"
          :md="colFocused('section')"
        >
          <SiteViewSectionList
            v-if="page"
            :key="page.id"
            :page="page"
            :section="section"
            @focus="part => focusing = part"
            @click-section="s => { section = s}"
          />
        </v-col>
        <v-col
          class="flex-col"
          cols="12"
          :md="colFocused('item')"
        >
          <SiteViewItemList
            v-if="section"
            :key="section.id"
            :page="page"
            :section="section"
            :item="item"
            @focus="part => focusing = part"
            @click-item="i => { item = i}"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { DeviceFilter } from '@/components'
import router from '@/router'
import { computed, onMounted, ref } from '@vue/composition-api'
import SiteViewItemList from './SiteViewItemList.vue'
import SiteViewPageList from './SiteViewPageList.vue'
import SiteViewSectionList from './SiteViewSectionList.vue'
import useSite from './useSite'

export default {
  components: {
    SiteViewPageList, SiteViewSectionList, SiteViewItemList, DeviceFilter,
  },

  setup() {
    const { loadSite, site } = useSite()

    onMounted(() => loadSite(router.currentRoute.params.id))

    const urlSetting = computed(() => site.value.settings.find(s => s.key === 'site.url'))

    const devices = ref(['mobile', 'desktop'])

    const focusing = ref('')

    return {
      site,
      urlSetting,
      page: ref(null),
      section: ref(null),
      item: ref(null),
      devices,
      focusing,
      colFocused(part) {
        if (focusing.value === part) {
          return 6
        }

        return focusing.value ? 3 : 4
      },
    }
  },
}

</script>

<style>
.flex-col {
  transition: all 0.35s ease-out;
}
</style>
