var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.site ? [_c('h2', [_c('b', [_vm._v(_vm._s(_vm.site.nicename))]), _vm.site.main ? _c('v-chip', {
    staticClass: "ml-2"
  }, [_vm._v(" MAIN ")]) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.urlSetting.value) + " "), _c('router-link', {
    attrs: {
      "to": {
        name: 'site-setting-list',
        params: {
          id: _vm.site.id
        }
      }
    }
  }, [_vm._v(" Manage Settings ")])], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('device-filter')], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "flex-col",
    attrs: {
      "cols": "12",
      "md": _vm.colFocused('page')
    }
  }, [_c('SiteViewPageList', {
    attrs: {
      "site": _vm.site,
      "page": _vm.page
    },
    on: {
      "focus": function focus(part) {
        return _vm.focusing = part;
      },
      "click-page": function clickPage(p) {
        _vm.page = p;
      }
    }
  })], 1), _c('v-col', {
    staticClass: "flex-col",
    attrs: {
      "cols": "12",
      "md": _vm.colFocused('section')
    }
  }, [_vm.page ? _c('SiteViewSectionList', {
    key: _vm.page.id,
    attrs: {
      "page": _vm.page,
      "section": _vm.section
    },
    on: {
      "focus": function focus(part) {
        return _vm.focusing = part;
      },
      "click-section": function clickSection(s) {
        _vm.section = s;
      }
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "flex-col",
    attrs: {
      "cols": "12",
      "md": _vm.colFocused('item')
    }
  }, [_vm.section ? _c('SiteViewItemList', {
    key: _vm.section.id,
    attrs: {
      "page": _vm.page,
      "section": _vm.section,
      "item": _vm.item
    },
    on: {
      "focus": function focus(part) {
        return _vm.focusing = part;
      },
      "click-item": function clickItem(i) {
        _vm.item = i;
      }
    }
  }) : _vm._e()], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }