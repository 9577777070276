import { fetchSite } from '@/api/site/site'
import { ref } from '@vue/composition-api'

export default () => {
  const site = ref(null)

  const loadSite = id =>
    fetchSite(id).then(res => {
      site.value = res.data.data
    })

  return {
    site,
    loadSite,
  }
}
