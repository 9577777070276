var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    on: {
      "click": _vm.replicateItem
    }
  }, [_vm._v(" Add + ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }