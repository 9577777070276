<template>
  <div>
    <a @click="replicateItem">
      Add +
    </a>
  </div>
</template>

<script>
import { replicateSiteItem } from '@/api/site/site'
import { useNotifyErrors } from '@/composables'
import { ref } from '@vue/composition-api'
import { orderBy } from 'lodash'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const replicateItem = async () => {
      if (loading.value) return

      // await //
      loading.value = true
      const lastId = orderBy(props.items, ['id'], ['desc'])[0].id
      await replicateSiteItem(lastId).catch(useNotifyErrors).finally(() => {
        loading.value = false
      })
      emit('created')
    }

    return {
      replicateItem,
    }
  },
}
</script>
