import { destroySiteSection, fetchSiteSections } from '@/api/site/site'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'Key',
      value: 'key',
    },
    {
      text: 'Type',
      value: 'type',
    },
    {
      text: 'Sort',
      value: 'sort',
    },
    {
      text: 'Reserved',
      value: 'reserved',
    },
    {
      text: 'Active',
      value: 'active',
    },
    {
      text: 'Selection',
      value: 'selection',
    },
    {
      text: 'Required Feature',
      value: 'feature_id',
    },
    {
      text: 'Notes',
      value: 'notes',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = reactive(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: 999,
    },
  )

  // Filter
  const filters = reactive({
    site_id: null,
    page_id: null,
    sort: '+sort',
  })

  const loadData = async () => {
    loading.value = true

    try {
      const { data } = await fetchSiteSections(useTableOptions(options, parseFilters()))

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters[key],
        }),
        {},
      )

  const deleteSiteSections = obj => {
    destroySiteSection(obj.id)
      .then(() => {
        useNotifySuccess({
          content: 'Delete Success',
        })
        loadData()
      })
      .catch(useNotifyErrors)
  }

  watch([options], (newVal, oldVal) => {
    console.log('new', newVal)
    console.log('old', oldVal)
    if (Object.keys(oldVal[0]).length > 3) {
      // skip doing loadData when initalizing
      loadData()
    }
  })

  watch(
    () => filters.page_id,
    debounce(() => {
      loadData()
    }, 1),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadData,
    deleteSiteSections,
  }
}
